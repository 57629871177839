import React from "react";
import "../styles/classes.scss";
import CumbiaClass from "../images/cumbia-lesson.jpg";

const CumbiaClasses = () => (
  <div className="inner_content_swipe">
    <div className="classes-container">
    
      <div className="classes-content">
      <img src={CumbiaClass} alt="Cumbia Class" height="300px" width="300px" />
        <div className="services_content">
          <h1>Learn the Rhythms of Cumbia Dance Lessons in Orange County</h1>
          <p>
          Cumbia is more than just steps - it celebrates life, culture, and community. This lively Latin dance is simple and easy to learn, which makes it one of the most beginner-friendly styles of dance. 
          </p>
          <p>
          With little help you can easily learn how to dance cumbia and master the steps. If you also want to master this exotic dance form, you're at the right place.

          </p>
          <p>
          At RF Dance, We cater to all levels, from beginners to seasoned dancers. In a fun and supportive atmosphere, you’ll learn to move to the music, build confidence, and celebrate life through dance!

          </p>
        </div>
      </div>

      <div className="paragraph">
        <div >
          <h2>Join Us for Cumbia Dance Classes in Orange County</h2>
          <p>
          Learning Cumbia is possible for anyone who wishes to do so! Here at RF Dance classes, we're offering dynamic Cumbia dance lessons tailored to young and professional dancers in Orange County. Under the guidance of our passionate instructors, you'll master the infectious pulse of Cumbia in no time. Cumbia's infectious energy is guaranteed to add a fresh dimension to your dance repertoire, and don't worry if you don't have a partner to learn with. 
          </p>
          <p>
          The social atmosphere of our Cumbia dance classes is the perfect space to meet fellow dancers. Don't miss out – join us in this exuberant adventure of fun and excitement.
          </p>
        </div>
      </div>

      <div className="paragraph">
        <div >
          <h2>Master the Rhythm: Find the Best Cumbia Dance Lessons Near You!</h2>
          <p>Experience the lively energy of Cumbia, a popular dance found in Latin nightclubs worldwide! Cumbia began as a courtship dance within African communities in Mexico, Colombia, and Panama.
          </p>
          <p>It evolved with European musical influences and became a dynamic dance that captivates dancers of all levels. Cumbia makes up at least 20% of the playlist at Latin clubs. </p>
          <p>With our Cumbia Dance Lessons, you can join the dance floor and learn to move to the irresistible rhythm of Cumbia!</p>
         
        </div>
      </div>

      <div className="paragraph">
        <div >
          <h2>Ready to start dancing? Join us Today!</h2>
          <p>Join RF Dance’s cumbia dance classes and experience Cumbia's rich cultural heritage and irresistible beats. Explore the various cumbia dance variations and express yourself with confidence and enthusiasm. 
          </p>
          <p>Experienced and enthusiastic instructors at RF Dance will guide you to mastering footwork, expressive body isolations, and much more. </p>
          <p>Don't wait—join RF Dance Academy; you won't regret it.</p>
         
        </div>
      </div>
    </div>
  </div>
);

export default CumbiaClasses;
