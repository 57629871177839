import React, { useState } from 'react';
import leftArrow from '../images/left-arrow.png';
import rightArrow from '../images/right-arrow.png';
const TestimonialSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const testimonials = [
        {
            text: "I had a great time at the RF Dance studio. The instructors taught the steps well and were flexible with timings. They made me feel comfortable and confident on the dance floor. The studio was spacious and clean, and the music was lively and upbeat. I learned so much about the cumbia dance. I would recommend this dance studio to anyone wanting to learn Cumbia dance. It was a wonderful experience!.",
            author: "Marissa F.",
            rating: 5
        },
        {
            text: "I recently attended their Cumbia class, and it was an amazing experience! The instructor was talented and patient, they made learning a fun experience. I learned rock steps and body coordination has improved. Overall, it was a fun and rewarding experience that I highly recommend to anyone interested in dance! ",
            author: "Meli Misfit",
            rating: 5
        },
        {
            text: "Best dance academy in Orange County! Anyone can learn Cumbia dance easily with them. They have a beautiful ambiance, are not crowded, and personalize their classes according to your needs. For them, dance is fun.",
            author: "David Valenzuela",
            rating: 5
        }
    ];


    const nextTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };

    const prevTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };

    return (

        <div className="testimonial-slider">
            <div className="container testimonial-slider-inner">
                <div className='heading'><h3>Testimonials</h3></div>
                <div className="testimonial">
                    <div className="testimonial-text">{testimonials[currentIndex].text}</div>
                    <div className="testimonial-author" itemProp="author">{testimonials[currentIndex].author}</div>
                    <div className="rating" itemScope itemType="http://schema.org/Rating">
                        <meta itemProp="ratingValue" content={testimonials[currentIndex].rating} />
                        <meta itemProp="bestRating" content={testimonials[currentIndex].rating} />
                        {[...Array(testimonials[currentIndex].rating)].map((_, index) => (
                            <span key={index} className="star">★</span>
                        ))}
                    </div>
                </div>
                <div className="slider-controls">
                    <span className='slider-arrows prev' onClick={prevTestimonial}>
                        <img src={leftArrow} /></span>
                    <span className='slider-arrows next' onClick={nextTestimonial}><img src={rightArrow} /></span>
                </div>
            </div>
        </div>
    );
};

export default TestimonialSlider;
