import React  from "react"

export default class MindbodyWidget extends React.Component {
  
  constructor(props) {
    super(props)

    this.state = {
      ...this.props
    }
  }
  componentDidMount() {
    
    const mbScript = (typeof document !== `undefined`  ? document.getElementById(this.state.id) : undefined)

    if (!mbScript && document) {
      this.createMindBodyScript()
    }

    this.setState(this.state)
  }

  createMindBodyScript () {
    const script = (typeof document !== `undefined`  ? document.createElement("script") : undefined);
    script.src = this.props.contract || "https://widgets.healcode.com/javascripts/healcode.js"
    script.async = true
    script.id = this.state.id
    if (typeof document !== `undefined` ) {
      document.body.appendChild(script)
    }
  }

  render() {
    return (
      <div>
        <healcode-widget 
          data-version={this.state.version || ``}
          data-link-class={this.state.linkClass || ``}
          data-site-id={this.state.siteId || ``}
          data-mb-site-id={this.state.mbSiteId || ``}
          data-type={this.state.type || ``}
          data-inner-html={this.state.innerHtml || ``}
          data-widget-partner={this.state.widgetPartner || ``}
          data-widget-id={this.state.widgetId || ``}
          data-service-id={this.state.serviceId || ``}
          data-widget-version={this.state.widgetVersion || ``} />
      </div>
      
    )
  }
}

