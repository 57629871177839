import React, { useState } from 'react';

// Individual FAQ item component
const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
    const faqclass = isOpen ? 'faqs active' : 'faqs';

    const faqContentClass = isOpen ? 'faqs_content active_active' : 'faqs_content';

    return (
        <div className={faqclass}>
            <h3 itemProp="name" className='toggle_title' onClick={toggleOpen}>
                {question}
                <span className="arrow"></span>
            </h3>
            <div className={faqContentClass} itemScope itemType="https://schema.org/Answer" itemProp="acceptedAnswer">
                <p itemProp="text">{answer}</p>
            </div>
        </div>
    );
};

// FAQ list component
const FAQList = ({ items }) => {
    return (
         <div itemScope itemType="https://schema.org/FAQPage">
                {items.map((item, index) => (
                    <div key={index} itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
                    <FAQItem key={index} question={item.question} answer={item.answer} />
                    </div>
                ))}
        </div>
    );
};

// Main App component with provided FAQ data
const FAQCumbia = () => {
    const faqs = [
        {
            question: "What is Cumbia dance and what makes it unique?",
            answer: "Cumbia is a Latin dance with roots in Colombia. It's a vibrant mix of cultures with lively rhythm and dynamic partner interactions. "
        },
        {
            question: "Are your Cumbia dance classes suitable for beginners?",
            answer: "Yes! Our Cumbia classes are beginner-friendly. We break down the steps, our instructors are patient, and we focus on fun while building your Cumbia skills."
        },
        {
            question: "What can I expect from a typical Cumbia dance class?",
            answer: "Our Cumbia classes are all about fun and learning! We break down the basic Cumbia steps so you can groove to the music. We also teach how to add cool moves like hip sways and turns. We offer optional partners, too!"
        },
        {
            question: " Do I need a partner to join the Cumbia dance classes?",
            answer: "No partner needed for Cumbia classes! We focus on solo skills with an optional partner for extra fun. Come solo, meet new people, and dance the Cumbia beat!."
        },
        {
            question: "What should I wear and bring to a Cumbia dance class?",
            answer: "Cumbia class essentials: comfy clothes like leggings, breathable tops, supportive shoes (sneakers or dance shoes), and a water bottle!."
        },
        {
            question: "Are there any prerequisites or dance experience required to join the classes?",
            answer: "No prior dance experience is necessary to join our classes. All levels are welcome!"
        },
        {
            question: "Can I try a Cumbia dance class before committing to a full session?",
            answer: "Yes, absolutely! We offer trial Cumbia dance classes for those who want to experience our instruction before committing to a full session. Contact us to schedule your trial class today!"
        }
    ];

    return (
        <div className="faq_section">
            <h3 className='main_heading'>FAQ</h3>
            <FAQList items={faqs} />
        </div>
    );
};

export default FAQCumbia;
